import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ConfigService {

  private APIUrl = environment.apiUrl;
  private azureStorageSASUrl = environment.azureStorageSASUrl;
  private azureStorageCatalog = environment.azureStorageCatalog;
  private _gisAPIUrl = environment.gisApiUrl;
  private _apyKey = environment.apiKey;
  private _WorldStreetThumbnailMapUrl = '../../../assets/images/world_street_map.jpg';
  /// 
  private _arcgisServerUrl = environment.arcgisServerUrl;

  constructor() { }

  public getAPIUrl() {
    return this.APIUrl;
  }

  get AzureStorageSASUrl() {
    return this.azureStorageSASUrl;
  }

  get AzureStorageCatalog() {
    return this.azureStorageCatalog;
  }

  get gisAPIUrl() {
    return this._gisAPIUrl;
  }

  get arcgisServerUrl() {
    return this._arcgisServerUrl;
  }

  get WorldStreetThumbnailMapUrl() {
    return this._WorldStreetThumbnailMapUrl;
  }

  get ApiKey() {
    return this._apyKey;
  }
}
