import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HeaderComponent } from './header.component';
import { LogoModule } from '../logo/logo.module';
import { ExternalLinksModule } from '../external-links/external-links.module';
import { NavigationModule } from '../navigation-primary/navigation.module';


@NgModule({
  declarations: [HeaderComponent],
  imports: [
    CommonModule,
    LogoModule,
    ExternalLinksModule,
    NavigationModule
  ],
  exports: [HeaderComponent]
})
export class HeaderModule { }
