import { Component } from '@angular/core';

@Component({
  selector: 'app-section-confirmation',
  templateUrl: './section-confirmation.component.html',
  styleUrls: ['./section-confirmation.component.css']
})
export class SectionConfirmationComponent {

}
