<app-header></app-header>
<main>
    <router-outlet></router-outlet>
</main>
<section *ngIf="!isConfirmed">
  <app-section-confirmation>
    <button (click)="confirmUser()" class="btn outlined">Зрозуміло</button>
  </app-section-confirmation>
</section>
<app-footer></app-footer>

