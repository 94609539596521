import { APP_INITIALIZER, ErrorHandler, NgModule } from '@angular/core';
import { Router } from "@angular/router";
import { BrowserModule } from '@angular/platform-browser';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';

import { HeaderModule } from './components/header/header.module';
import { FooterModule } from './components/footer/footer.module';

import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { ApiKeyInterceptor } from './core/interceptors/api-key.interceptor'
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { ErrorHandlerService } from './services/error-handler.service';

import { SectionConfirmationModule } from './components/section-confirmation/section-confirmation.module';
import * as Sentry from "@sentry/angular-ivy";

@NgModule({
  declarations: [
    AppComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    RouterModule,
    CommonModule,
    HeaderModule,
    FooterModule,
    SectionConfirmationModule    
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: ApiKeyInterceptor, multi: true },
    {
      provide: ErrorHandler,
      useClass: ErrorHandlerService,
      useValue: Sentry.createErrorHandler({
        showDialog: false,
      }),
    }, {
      provide: Sentry.TraceService,
      deps: [Router],
    },
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => { },
      deps: [Sentry.TraceService],
      multi: true,
    },
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
