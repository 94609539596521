import { Component } from '@angular/core';

@Component({
  selector: 'app-navigation-primary',
  templateUrl: './navigation-primary.component.html',
  styleUrls: ['./navigation-primary.component.css']
})
export class NavigationPrimaryComponent {
  isMenuOpen = false;

  toggleMenu() {
    this.isMenuOpen = !this.isMenuOpen;
  }

  closeMenu() {
    this.isMenuOpen = false;
  }
}
