import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FooterComponent } from './footer.component';
import { ExternalLinksModule } from '../external-links/external-links.module';


@NgModule({
  declarations: [FooterComponent],
  imports: [
    CommonModule,
    ExternalLinksModule,
  ],
  exports: [FooterComponent]
})
export class FooterModule { }
