<div [class]="ExternalLinksInfo.gridList ? 'grid-list' : 'external-links'">
  <ng-content></ng-content>
  <a href="https://mepr.gov.ua/" target="_blank" title="Міністерство захисту довкілля та природних ресурсів України">
    <img src="assets/images/logo-mineko.webp" width="162" height="40" alt="Міністерство захисту довкілля та природних ресурсів України" />
  </a>
  <a href="https://dazv.gov.ua/" target="_blank" title="Державне агентство України з управління зоною відчуження">
    <img src="assets/images/logo-dauzuzv.webp" width="162" height="40" alt="Державне агентство України з управління зоною відчуження" />
  </a>
  <a href="https://www.thegef.org/" target="_blank" title="Global Environment Facility">
    <img src="assets/images/logo-gef.webp" width="107" height="48" alt="Global Environment Facility" />
  </a>
  <a href="https://www.unep.org/" target="_blank" title="United Nations Environment Programme">
    <img src="assets/images/logo-unep.svg" width="114" height="40" alt="United Nations Environment Programme" />
  </a>
  <a href="http://aerogeo.org/" target="_blank" title="Всеукраїнська аеро-геодезична асоціація">
    <img src="assets/images/logo-vaga.webp" width="142" height="46" alt="Всеукраїнська аеро-геодезична асоціація" />
  </a>
</div> 
