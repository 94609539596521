<footer class="grid-container footer-container">
    <div class="grid-content-full footer-hero grid-container">
        <div class="grid-content section-content placement-center">
            <div class="width-md footer-about">
                <img class="logo" src="../../../assets/images/logo.webp" width="80" height="80" alt="Чорнобильський радіаційно-екологічний біосферний заповідник" />
                <div class="width-xs">
                    <p class="heading-md">Геоінформаційний портал</p> 
                    <p class="gutter font-thin body-lg">Чорнобильського радіаційно-екологічного біосферного заповідника</p>
                    <p class="body-sm font-thin">Проект розроблено Всеукраїнською аеро-геодезичною громадською організацією в рамках</p>
                    <p>Програми ООН з навколишнього середовища UNEP</p>
                </div>
            </div>
       </div>
    </div>
    <div class="grid-content footer-legal">
        <app-external-links></app-external-links>
        <p class="body-xs">
            ©2024 
        </p>
    </div>
</footer>