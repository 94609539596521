import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SectionConfirmationComponent } from './section-confirmation.component';



@NgModule({
  declarations: [
    SectionConfirmationComponent
  ],
  imports: [
    CommonModule
  ],
  exports: [SectionConfirmationComponent]
})
export class SectionConfirmationModule { }
