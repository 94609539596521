<div class="grid-container clr-background-primary-transparent clr-text-invert confirmation">
    <div class="grid-content section-col placement-center">
        <div class="width-sm section-content">
            <div class="section-col">
                <p>
                    Всі матеріали, розміщені на цьому сайті, призначені виключно для особистого використання та не можуть бути використані в комерційних цілях без попередньої письмової згоди власника сайту.
                </p>
                <p>
                    Будь ласка, звертайтеся за додатковою інформацією та дозволом.
                </p>
            </div>
            <div class="section-col placement-center">
                <ng-content></ng-content>
            </div>
        </div>
    </div>
</div>