import { Component, Input } from '@angular/core';
import { ExternalLinksInfo } from './external-links.module';

@Component({
  selector: 'app-external-links',
  templateUrl: './external-links.component.html',
  styleUrls: ['./external-links.component.css']
})
export class ExternalLinksComponent {

  @Input() ExternalLinksInfo: ExternalLinksInfo = {
    gridList: false,
  }

}
