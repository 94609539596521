import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { ConfigService } from '../../services/config.service';

@Injectable()
export class ApiKeyInterceptor implements HttpInterceptor {

  constructor(private config: ConfigService) { }

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    
    const modifiedRequest = request.clone({
      headers: request.headers.set('X-API-KEY', this.config.ApiKey)
    });

    return next.handle(modifiedRequest);
  }
}
