export const environment = {
  production: true,
  apiUrl: 'https://api.geo-portal.org.ua/api/',
  azureStorageSASUrl: 'https://ecogisstorage.blob.core.windows.net/?',
  azureStorageCatalog: 'ecogis-prod',
  apiKey: 'J-ehUzzJYm3OwLWMu5kpVHzc81jEKv8TBOUxlthl2vs',
  sentryEnable: true,
  arcgisServerUrl: 'https://gisserver.webgis.ua/gis/rest/services/',
  gisApiUrl: 'https://newapi.webgis.ua/'
};
