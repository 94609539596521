import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {
  title = 'portalGISApp';

  isConfirmed: boolean = true;
  constructor() { }

  ngOnInit(): void {
    let confirmed = localStorage.getItem('userConfirmed');
    if (confirmed) {
      this.isConfirmed = true;
    } else {
      this.isConfirmed = false;
    }
  }

  confirmUser() {
    localStorage.setItem('userConfirmed', 'confirmed');
    this.isConfirmed = true;
  }
}
