<nav class="navigation-primary text-uppercase font-thin" [ngClass]="{'open': isMenuOpen}">
    <a routerLink="/home" routerLinkActive="active-link" (click)="closeMenu()">
        Головна
    </a>
    <a routerLink="/library" routerLinkActive="active-link" (click)="closeMenu()">
        Бібліотека
    </a>
    <a routerLink="/media-library" routerLinkActive="active-link" (click)="closeMenu()">
        Медіатека
    </a>
    <a routerLink="/webgis" routerLinkActive="active-link" (click)="closeMenu()">
        WebGIS
    </a>
    <a routerLink="/about" routerLinkActive="active-link" (click)="closeMenu()">
        Про проект
    </a>
</nav>

<button class="hamburger" title="Навігація" [ngClass]="{'open': isMenuOpen}" (click)="toggleMenu()"></button>